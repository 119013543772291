<template>
  <div>
    <app-dialog
      v-model="open"
      :loading="loading"
      :disabled="disabled"
      :title="dialog.title"
      :primary-action-text="dialog.actionText"
      secondary-action-text="navigation.cancel"
      @submit="onSubmit"
    >
      <template v-if="disabled">
        <app-alert>
          {{ $t(dialog.permanentDebtWarning) }}
          <app-link-btn
            class="d-block mt-2"
            title="navigation.toPermanentDebt"
            variant="muted"
            :to="
              getPermanentDebtRoute({
                id: debtor.permanentDebts[0].id,
                location: debtor.permanentDebts[0].location
              })
            "
          />
        </app-alert>
      </template>

      <template v-else>
        <p>{{ $t(dialog.text) }}</p>

        <app-alert
          v-if="$te(dialog.permanentDeactivationWarning)"
          color="info"
          :icon="false"
          outlined
          class="subtitle-1 mt-8"
        >
          <div class="semi-bold">{{ $t('pleaseNote') }}:</div>
          {{ $t(dialog.permanentDeactivationWarning) }}
        </app-alert>

        <p v-if="$te(dialog.info)" class="mt-8 caption grey--text">
          {{ $t('hint') }}: {{ $t(dialog.info) }}
        </p>
      </template>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      :secondary-action-text="debtor.isLocation ? 'navigation.toLocation' : 'navigation.toDebtor'"
      :title="dialog.textConfirmed"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Debtors/store';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'EditDebtorStatusDialog',

  mixins: [ShowDialogMixin],

  props: {
    debtor: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    actionType() {
      return this.getQueryParam(this.queryParam.ACTION) === this.queryParam.ACTIVATE
        ? 'activate'
        : 'deactivate';
    },
    debtorType() {
      return this.debtor.isLocation ? 'Location' : 'Debtor';
    },
    disabled() {
      return this.actionType === 'deactivate' && this.debtor.permanentDebts.length > 0;
    },
    dialog() {
      return {
        title: `debtors.editDebtorStatus.${this.actionType}${this.debtorType}`,
        text: `debtors.editDebtorStatus.${this.actionType}${this.debtorType}Request`,
        info: `debtors.editDebtorStatus.${this.actionType}${this.debtorType}Info`,
        textConfirmed: `debtors.editDebtorStatus.${this.actionType}${this.debtorType}Confirmed`,
        action: this[`${this.actionType}${this.debtorType}`],
        actionText: `debtors.editDebtorStatus.${this.actionType}`,
        permanentDebtWarning: `debtors.editDebtorStatus.permanentDebtWarning${this.debtorType}`,
        permanentDeactivationWarning:
          this.actionType === 'deactivate' && !this.debtor.isLocation
            ? 'debtors.editDebtorStatus.deactivateDebtorWarning'
            : this.actionType === 'deactivate' && this.debtor.isLastActiveLocation
            ? 'debtors.editDebtorStatus.deactivateLastLocationWarning'
            : ''
      };
    }
  },

  methods: {
    ...mapActions(NAMESPACE, [
      'activateDebtor',
      'deactivateDebtor',
      'activateLocation',
      'deactivateLocation'
    ]),

    async onSubmit() {
      this.loading = true;

      const { error } = await this.dialog.action(this.debtor.id);

      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
