<template>
  <app-dialog
    :disabled="disableUpload"
    :loading="loading"
    :primary-action-text="!dropzoneEmpty ? 'upload.upload' : null"
    :secondary-action-text="!dropzoneEmpty ? 'navigation.cancel' : null"
    :value="value"
    v-bind="$attrs"
    @submit="loading = true"
    v-on="$listeners"
  >
    <voucher-templates v-if="showTemplates" @cancel="showTemplates = false" />
    <template v-else>
      <div v-if="!dropzoneEmpty" class="d-flex flex-column align-center justify-center mb-12">
        <div class="icon__wrapper">
          <icon-vouchers width="36px" height="36px" />
        </div>
        <h3 class="dropzone-custom-title semi-bold mt-4">{{ $t('upload.yourInvoices') }}</h3>
      </div>
      <app-dropzone
        v-model="invoices"
        :additional-form-data="additionalFormData"
        :has-empty-queue.sync="disableUpload"
        :max-files="null"
        :start-upload="loading"
        :url="url"
        @error="loading = false"
        @success="finishUpload"
      >
        <template #title>
          <div
            v-html="
              $t('upload.dropzoneLocation', {
                location: `${debtor.name}<span class='primary--text caption' style='vertical-align: top;'>*</span>`
              })
            "
          ></div>
        </template>

        <template #hint>
          <v-row no-gutters class="caption primary--text d-flex text-left mt-8 mb-n6 ml-n6 mr-n6">
            <v-col cols="auto" class="text--small mr-1">*</v-col>
            <v-col>
              {{ $t('upload.hintAddressMatch') }}
            </v-col>
          </v-row>
        </template>
      </app-dropzone>
      <voucher-upload-additions v-if="dropzoneEmpty" @showTemplates="showTemplates = true" />
    </template>
  </app-dialog>
</template>

<script>
import VoucherTemplates from '@/modules/Upload/components/VoucherTemplates';
import { BASE_URL } from '@/plugins/axios';
import AppDropzone from '@/shared/components/AppDropzone';
import VoucherUploadAdditions from '@/shared/components/VoucherUploadAdditions';

export default {
  name: 'LocationUploadDialog',

  props: {
    additionalFormData: {
      type: Object,
      default: null
    },
    debtor: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AppDropzone,
    VoucherTemplates,
    VoucherUploadAdditions
  },

  data: () => ({
    invoices: [],
    showTemplates: false,
    disableUpload: false,
    loading: false
  }),

  watch: {
    value() {
      if (this.value) {
        return;
      }

      setTimeout(() => this.reset(), 200);
    }
  },

  computed: {
    url() {
      return `${BASE_URL}/location/${this.debtor.id}/voucher-upload`;
    },
    dropzoneEmpty() {
      return this.invoices.length === 0;
    }
  },

  methods: {
    reset() {
      this.invoices = [];
    },

    finishUpload() {
      this.loading = false;
      this.$emit('success');
    }
  }
};
</script>
