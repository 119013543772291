const filterChangedProperties = (base, comparison) => {
  // returns only properties of base object, which are different from comparison object,
  // nested objects are only returned, if any of it's properties changed
  return Object.entries(base).reduce((changes, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      const nestedChanges = filterChangedProperties(base[key], comparison[key]);

      if (Object.keys(nestedChanges).length > 0) {
        changes[key] = nestedChanges;
      }

      return changes;
    }

    if (comparison[key] !== base[key]) {
      changes[key] = value;
    }

    return changes;
  }, {});
};

export default filterChangedProperties;
