<template>
  <div>
    <app-dialog
      v-model="open"
      primary-action-text="debtors.refreshLimit"
      secondary-action-text="navigation.cancel"
      title="debtors.limitRefresh.refreshFor"
      :loading="loading"
      @submit="submitLimitRefresh"
    >
      <v-row no-gutters>
        <v-col>
          <div class="semi-bold">{{ debtor.name }}</div>
          <span v-if="debtor.address.streetAndHouse">
            {{ debtor.address.streetAndHouse }},&#32;
          </span>
          <span>{{ debtor.address.zip }} {{ debtor.address.city }}</span>
        </v-col>
      </v-row>

      <v-row no-gutters justify="space-between" class="mx-3" style="margin-top: 3.75rem">
        <v-col cols="auto">{{ $t('debtors.limitIncrease.currentLimit') }}:</v-col>
        <v-col class="text-right">
          {{ debtor.limit.approvedLimit | formatCurrency }}
        </v-col>
        <v-col cols="12" class="caption text--muted text-right">
          {{ $t('debtors.since') }}: {{ debtor.limit.limitUpdated | formatDate }}
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col class="d-inline-flex">
          <p>{{ $t('debtors.limitRefresh.request') }}</p>
          <span class="text--small primary--text">*</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="caption primary--text d-flex">
          <span class="text--small">*</span>
          <span>{{ $t('debtors.limitIncrease.durationInfo') }}</span>
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="debtors.limitRequestSent"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('debtors.limitRefresh.sent') }}</p>
    </app-dialog>
  </div>
</template>

<script>
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'RefreshLimitDialog',

  mixins: [ShowDialogMixin],

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['refreshLimit']),

    async submitLimitRefresh() {
      this.loading = true;

      const { error } = await this.refreshLimit({
        debtorIdentifier: this.$route.params.id
      });
      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
