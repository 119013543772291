<template>
  <filter-wrapper :has-active-filters="hasActiveFilters" @reset="resetAllFilter">
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <status-select v-model="statusSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <name-select v-model="debtorSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <custom-debtor-number-select v-model="customNumberSelection" />
      </v-col>
      <v-col v-if="permissions.debtors.showLimit" cols="12" sm="4" md="3" lg="2">
        <limit-select v-model="limitSelection" />
      </v-col>
      <v-col v-if="hasAdminView" cols="12" sm="4" md="3" lg="2">
        <debtor-type-select v-model="typeSelection" />
      </v-col>
    </v-row>

    <template :slot="isMobile ? 'active-filters' : 'default'">
      <v-row v-if="!isMobile || hasActiveFilters" dense>
        <v-col cols="12" class="pt-3 pb-4">
          <active-filter-chip
            v-for="(selection, index) in statusSelection"
            :key="selection"
            v-model="statusSelection"
            :index="index"
            icon="icon-gauge"
          >
            {{ selection | formatDebtorStatus }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in debtorSelection"
            :key="selection"
            v-model="debtorSelection"
            :index="index"
            icon="icon-clients"
          >
            {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in customNumberSelection"
            :key="selection"
            v-model="customNumberSelection"
            :index="index"
            icon="icon-hash"
          >
            {{ selection }}
          </active-filter-chip>
          <template v-if="isFactoring">
            <active-filter-chip
              v-for="(selection, index) in limitSelection"
              :key="selection"
              v-model="limitSelection"
              :index="index"
              icon="icon-money"
            >
              {{ selection | formatLimitSelection }}
            </active-filter-chip>
          </template>
          <template v-if="hasAdminView">
            <active-filter-chip
              v-for="(selection, index) in typeSelection"
              :key="selection"
              v-model="typeSelection"
              :index="index"
              icon="icon-target-client"
            >
              {{ selection | formatDebtorType }}
            </active-filter-chip>
          </template>

          <app-text-btn
            v-if="hasActiveFilters && !isMobile"
            color="background-text"
            class="caption"
            title="resetFilter"
            @click="resetAllFilter"
          />
        </v-col>
      </v-row>
    </template>
  </filter-wrapper>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import CustomDebtorNumberSelect from '@/modules/Debtors/components/Filter/CustomDebtorNumberSelect';
import DebtorTypeSelect from '@/modules/Debtors/components/Filter/DebtorTypeSelect';
import FilterWrapper from '@/shared/components/FilterWrapper';
import LimitSelect from '@/modules/Debtors/components/Filter/LimitSelect';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import NameSelect from '@/modules/Debtors/components/Filter/NameSelect';
import StatusSelect from '@/modules/Debtors/components/Filter/StatusSelect';

export default {
  name: 'DebtorsFilter',

  mixins: [ListFilterMixin],

  components: {
    ActiveFilterChip,
    CustomDebtorNumberSelect,
    DebtorTypeSelect,
    FilterWrapper,
    LimitSelect,
    NameSelect,
    StatusSelect
  }
};
</script>
