<template>
  <v-row no-gutters justify="end">
    <template v-for="(action, index) in filterItemsWithFulfilledCondition(actions)">
      <v-col :key="index" cols="auto">
        <tile-btn
          :disabled="!action.callback"
          :icon="action.icon"
          :loading="action.loading"
          :title="action.text"
          square
          @click="action.callback"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import TileBtn from '@/shared/components/TileBtn';

export default {
  name: 'VoucherUploadAdditions',

  components: { TileBtn },

  computed: {
    actions() {
      return [
        {
          icon: 'icon-permanent-debt',
          text: this.$t('permDebts'),
          callback: this.redirectToPermanentDebts,
          condition:
            this.permissions.permanentDebts.create &&
            this.routes[this.routeName.PERMANENT_DEBTS].condition !== false
        },
        {
          icon: 'icon-checklist',
          text: this.$t('upload.rules'),
          callback: this.openRules,
          condition: !!this.product.documents.upload.rules
        },
        {
          icon: 'icon-vouchers',
          text: this.$t('upload.templates'),
          callback: () => this.$emit('showTemplates')
        },
        {
          icon: 'icon-lightbulb',
          text: this.$t('upload.tips'),
          callback: this.openTips,
          condition: !!this.product.documents.upload.tips
        }
      ];
    }
  },

  methods: {
    openRules() {
      this.openFile(this.product.documents.upload.rules);
    },

    openTips() {
      this.openFile(this.product.documents.upload.tips);
    },

    openFile(filePath) {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', filePath);
      document.body.appendChild(link);
      link.click();
    },

    redirectToPermanentDebts() {
      if (this.$route.name === this.routeName.PERMANENT_DEBTS) {
        return this.setQueryParam(this.queryParam.UPLOAD_VOUCHER, false);
      }

      this.$router.push({ name: this.routeName.PERMANENT_DEBTS });
    }
  }
};
</script>
