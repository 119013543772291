<template>
  <app-select
    filter
    :items="states"
    :label="$t('debtors.limit')"
    label-icon="icon-money"
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'LimitSelect',

  computed: {
    states() {
      return Object.keys(this.$t('debtors.limitSelectOptions')).map((option) => ({
        label: this.$t(`debtors.limitSelectOptions.${option}`),
        value: option
      }));
    }
  }
};
</script>
