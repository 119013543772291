<template>
  <v-container>
    <v-row v-if="loading" justify="center" class="my-6">
      <v-col cols="auto">
        <app-spinner />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <template v-if="!loading && permanentDebts.length > 0">
          <v-list class="pa-0 ma-n2">
            <v-list-item
              v-for="permanentDebt in permanentDebts"
              :key="permanentDebt.id"
              class="px-0 mb-2"
              :to="getPermanentDebtRoute({ id: permanentDebt.id, location: details.name })"
            >
              <v-list-item-content class="subtitle-1 pa-2">
                <v-list-item-title class="semi-bold">
                  {{ permanentDebt.subject || '-' }}
                </v-list-item-title>
                <v-list-item-subtitle class="text--text">
                  <div v-if="Date.now() < permanentDebt.startDate">
                    {{ $t('permanentDebts.startsOn') }} {{ permanentDebt.startDate | formatDate }}
                  </div>
                  <div v-else-if="permanentDebt.endDate">
                    {{ $t('permanentDebts.endsOn') }} {{ permanentDebt.endDate | formatDate }}
                  </div>
                  <div v-else>{{ $t('permanentDebts.ongoing') }}</div>

                  <div>
                    {{ $t('permanentDebts.nextDirectDebit') }} {{ $t('worth') }}
                    {{ permanentDebt.amount | formatCurrency }}
                    {{ $t('on') }}
                    {{ permanentDebt.nextExecutionDate | formatDate }}
                  </div>

                  <div class="overline grey--text">
                    {{ $t('permanentDebts.customNumber') }}: {{ permanentDebt.customNumber }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon size="1.2rem">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>

        <template v-if="!loading && permanentDebts.length === 0">
          {{ $t('permanentDebts.noDataForLocation') }}
        </template>
      </v-col>
    </v-row>

    <v-row v-if="hasAdminView && !loading" class="mt-12">
      <app-api-log
        :data="permanentDebtsRaw"
        api-name="/location/${identifier}/permanent-debt-vouchers"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Debtors/store';

export default {
  name: 'TabLocationPermanentDebts',

  props: {
    details: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    permanentDebts: [],
    permanentDebtsRaw: [],
    loading: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['fetchLocationPermanentDebts'])
  },

  async created() {
    this.loading = true;
    const response = await this.fetchLocationPermanentDebts(this.details.id);
    this.permanentDebts = response.response;
    this.permanentDebtsRaw = response.responseRaw;
    this.loading = false;
  }
};
</script>

<style scoped>
.v-list-item__subtitle {
  white-space: normal;
}
</style>
