<template>
  <v-container class="pa-0">
    <v-row :no-gutters="noGutters">
      <v-col class="subtitle text-uppercase">
        {{
          filterPurchased
            ? $t('debtors.showPurchasedInvoicesInVoucherList')
            : $t('debtors.showInvoicesInVoucherList')
        }}
      </v-col>
    </v-row>

    <v-row :no-gutters="noGutters">
      <v-col v-for="(option, index) in linksToVoucherList" :key="index" cols="auto" class="mr-4">
        <router-link
          :to="{
            name: routeName.VOUCHERS,
            query: {
              location: locationNames,
              status: option.status,
              purchaseStatus: option.purchaseStatus
            }
          }"
        >
          {{ $t(option.title) }}
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PurchaseStatus from '@/modules/Vouchers/statics/purchaseStatus';

export default {
  name: 'LinksToVoucherList',

  props: {
    details: {
      type: Object,
      required: true
    },
    filterPurchased: {
      type: Boolean,
      default: false
    },
    noGutters: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    linksToVoucherList() {
      return [
        {
          title: 'debtors.linkOptions.all',
          purchaseStatus: this.filterPurchased ? [PurchaseStatus.PURCHASED] : undefined
        },
        {
          title: 'debtors.linkOptions.open',
          status: this.product.voucherStatusToShowGroups.open,
          purchaseStatus: this.filterPurchased ? [PurchaseStatus.PURCHASED] : undefined
        },
        {
          title: 'debtors.linkOptions.closed',
          status: this.product.voucherStatusToShowGroups.closed,
          purchaseStatus: this.filterPurchased ? [PurchaseStatus.PURCHASED] : undefined
        }
      ];
    },
    locationNames() {
      if (this.$route.name === this.routeName.PERMANENT_DEBTS) {
        return [this.details.location.name];
      }

      return this.details.isLocation
        ? [this.details.name]
        : [...new Set(this.details.locations.map((location) => location.name))];
    }
  }
};
</script>
