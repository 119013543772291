<template>
  <v-container class="px-0">
    <app-labeled-data no-gutters text-right label="debtors.limit" class="mb-0">
      {{ details.limit.approvedLimit | formatCurrency }}
    </app-labeled-data>
    <app-labeled-data
      :class="{ 'caption text--muted': !isMobile }"
      no-gutters
      text-right
      label="debtors.since"
    >
      {{ details.limit.limitUpdated | formatDate }}
    </app-labeled-data>
    <app-labeled-data no-gutters text-right label="debtors.validUntil">
      {{ details.limit.expirationDate | formatDate }}
    </app-labeled-data>
    <app-labeled-data no-gutters text-right label="debtors.availableLimit">
      {{ details.limit.availableLimit | formatCurrency }}
    </app-labeled-data>

    <v-divider class="my-4" />
    <v-row no-gutters>
      <v-col class="text--muted caption">{{ $t('debtors.limitInfo') }}</v-col>
    </v-row>

    <v-row v-if="details.openPurchasedInvoices.length === 0" no-gutters>
      <v-col>
        {{ $t('debtors.noPurchasedInvoices') }}
      </v-col>
    </v-row>

    <template v-else>
      <links-to-voucher-list :details="details" filter-purchased no-gutters />

      <v-row no-gutters class="mt-10">
        <v-col class="subtitle text-uppercase">{{ $t('debtors.lastOpenPurchasedInvoices') }}</v-col>
      </v-row>

      <v-row
        v-for="voucher in details.openPurchasedInvoices"
        :key="voucher.id"
        no-gutters
        class="mb-4"
      >
        <v-col class="pr-2">
          {{ voucher.numberToShow || '-' }}
        </v-col>
        <v-col class="text-right">{{ voucher.amount | formatCurrency }}</v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import LinksToVoucherList from '@/shared/components/LinksToVoucherList';

export default {
  name: 'TabDebtorLimit',

  components: { LinksToVoucherList },

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
