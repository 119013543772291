<template>
  <div>
    <app-data-table
      :error="listError"
      :expanded="expanded"
      :headers="tableHeaders"
      :items="tableData"
      :loading="loadingDebtors || !initiated"
      :total-data-length="count"
    >
      <template #title>
        <div :class="{ 'table-title__content': true, 'flex-column': isMobile }">
          <div :class="classesTitleLeft">{{ $tc('debtorsCounted', count) }}</div>
          <div v-if="isFactoring" :class="{ [classesTitleRight]: true, 'mt-2': isMobile }">
            <creditor-limits-menu v-if="creditorLimits" :creditor-limits="creditorLimits">
              {{ $t('debtors.availableCreditorLimit') }}:
              <span v-if="creditorLimits" :class="classesTitleRightValue">
                {{ creditorLimits.availableLimit | formatCurrency }}
              </span>
            </creditor-limits-menu>
          </div>
        </div>
      </template>

      <template #item="{ item }">
        <app-table-row
          :item="item"
          :bordered="item.limit.isOnBlacklist"
          :highlighted="isShownInDetails(item.id)"
          @click="setDetailedParams(item.id)"
        >
          <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
            <app-status-chip :value="item.status" />
          </app-table-data>
          <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
            {{ item.name }}
            <div class="subtitle-1 grey--text">{{ item.city }}</div>
          </app-table-data>
          <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
            <span v-if="hasAdminView" class="subtitle-1 grey--text">
              {{ item.type | formatDebtorType }}
            </span>
          </app-table-data>
          <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
            {{ item.invoiceVolume | formatCurrency }}
            <div v-if="isFactoring" class="subtitle-1 grey--text">
              {{ item.openInvoiceVolume | formatCurrency }}
            </div>
          </app-table-data>
          <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
            <template v-if="isFactoring">
              {{ item.purchasedInvoiceVolume | formatCurrency }}
              <div class="subtitle-1 grey--text">
                {{ item.openPurchasedInvoiceVolume | formatCurrency }}
              </div>
            </template>
            <template v-else>
              {{ item.openInvoiceVolume | formatCurrency }}
            </template>
          </app-table-data>

          <app-table-data v-if="isFactoring" :sorted="isSorted(5)" :header="tableHeaders[5]">
            {{ item.notPurchasedInvoiceVolume | formatCurrency }}
            <div class="subtitle-1 grey--text">
              {{ item.openNotPurchasedInvoiceVolume | formatCurrency }}
            </div>
          </app-table-data>
          <app-table-data
            v-if="isFactoring && permissions.debtors.showLimit"
            :sorted="isSorted(6)"
            :header="tableHeaders[6]"
          >
            <v-menu :disabled="isMobile" open-on-hover bottom offset-y min-width="270">
              <template #activator="{ on }">
                <div class="semi-bold" v-on="on">
                  <template v-if="item.limit.isExpired">{{ $t('debtors.expired') }}</template>
                  <template v-else>{{ item.limit.approvedLimit | formatCurrency }}</template>
                </div>
              </template>

              <v-list class="limit-menu">
                <v-list-item class="caption">
                  <span
                    v-html="
                      item.limit.isExpired
                        ? $t('debtors.limitExpiredOn', {
                            date: formatDate(item.limit.expirationDate)
                          })
                        : $t('debtors.limitExpiresOn', {
                            date: formatDate(item.limit.expirationDate)
                          })
                    "
                  ></span>
                </v-list-item>
                <v-list-item
                  v-if="item.openPurchasedInvoices.length === 0"
                  style="min-height: 28px"
                >
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="caption">
                      {{ $t('debtors.noOpenPurchasedInvoices') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <template v-else>
                  <v-subheader class="caption">
                    {{ $t('debtors.lastOpenPurchasedInvoices') }}:
                  </v-subheader>
                  <v-list-item
                    v-for="(invoice, index) in item.openPurchasedInvoices"
                    :key="`${item.id}_invoice_${invoice.numberToShow}_${index}`"
                    style="min-height: 28px"
                  >
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="caption grey--text">
                        {{ invoice.numberToShow || '-' }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="py-0">
                      <v-list-item-title class="caption text-right">
                        {{ invoice.amount | formatCurrency }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>

            <div class="subtitle-1 grey--text">
              {{ item.limit.availableLimit | formatCurrency }}
            </div>
          </app-table-data>

          <app-table-data
            v-if="!isMobile || (isMobile && item.locations.length > 0)"
            :class="{ 'justify-center': isMobile }"
          >
            <app-icon-btn
              v-if="item.locations.length > 0"
              :icon="
                expanded.find((debtor) => debtor.id === item.id)
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down'
              "
              large
              @click.stop="expandRow(item)"
            />
          </app-table-data>
        </app-table-row>
      </template>

      <template #expanded-item="{ item }">
        <template v-for="(location, index) in item.locations">
          <app-table-row
            :key="location.id"
            :item="item"
            :highlighted="isShownInDetails(`${item.id},${location.id}`)"
            expanded
            :class="{
              last: item.locations.length === index + 1,
              first: index === 0
            }"
            @click="setDetailedSubParams({ locationId: location.id, debtorId: item.id })"
          >
            <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
              <v-row no-gutters class="flex-nowrap" align="center">
                <v-col v-if="!isMobile" cols="auto" class="body-1 align-self-baseline">
                  <div class="dot__wrapper">
                    <icon-dot />
                  </div>
                </v-col>
                <v-col cols="auto" :class="{ shrink: true, 'text-right': isMobile }">
                  {{ $t('location') }}
                </v-col>
              </v-row>
              <app-status-chip :value="location.status" />
            </app-table-data>
            <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
              {{ location.name }}
              <div class="subtitle-1 grey--text">
                <template v-if="location.address.street">
                  {{ location.address.streetAndHouse }},&#32;
                </template>
                {{ location.address.zip }} {{ location.address.city }}
              </div>
            </app-table-data>
            <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
              {{ location.customNumber || '-' }}
            </app-table-data>
            <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
              {{ location.invoiceVolume | formatCurrency }}
              <div v-if="isFactoring" class="subtitle-1 grey--text">
                {{ location.openInvoiceVolume | formatCurrency }}
              </div>
            </app-table-data>
            <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
              <template v-if="isFactoring">
                {{ location.purchasedInvoiceVolume | formatCurrency }}
                <div class="subtitle-1 grey--text">
                  {{ location.openPurchasedInvoiceVolume | formatCurrency }}
                </div>
              </template>
              <template v-else>
                {{ location.openInvoiceVolume | formatCurrency }}
              </template>
            </app-table-data>
            <app-table-data v-if="isFactoring" :sorted="isSorted(5)" :header="tableHeaders[5]">
              {{ location.notPurchasedInvoiceVolume | formatCurrency }}
              <div class="subtitle-1 grey--text">
                {{ location.openNotPurchasedInvoiceVolume | formatCurrency }}
              </div>
            </app-table-data>
            <app-table-data
              v-if="isFactoring && permissions.debtors.showLimit"
              :sorted="isSorted(6)"
              :header="tableHeaders[6]"
            >
              {{ $t('debtors.limitAbove') }}
            </app-table-data>
            <app-table-data />
            <v-divider v-if="isMobile && index + 1 < item.locations.length" class="mx-4" />
          </app-table-row>
        </template>
      </template>

      <template #no-data>
        <div v-if="empty" class="mx-4">
          <template v-if="permissions.debtors.createDebtor">
            <h2 class="mb-8">{{ $t('debtors.noData') }}</h2>
            <p class="mb-10" style="max-width: 600px">{{ $t('debtors.noDataInfo') }}</p>
            <app-btn @click="openCreateDebtorDialog">
              {{ $t('debtors.createFirst') }}
            </app-btn>
          </template>
          <p v-else>{{ $t('debtors.noData') }}</p>
        </div>
        <div v-else>
          {{ $t('noMatchingResults') }}
        </div>
      </template>
    </app-data-table>

    <app-api-log v-if="hasAdminView" :data="debtors" api-name="/debtor/list" />
  </div>
</template>

<script>
/* eslint-disable sonarjs/no-duplicate-string */
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapState } from 'vuex';
import TableMixin from '@/mixins/TableMixin';
import CreditorLimitsMenu from '@/modules/Vouchers/components/CreditorLimitsMenu';
import formatDate from '@/helper/filter/formatDate';

export default {
  name: 'DebtorsTable',

  mixins: [TableMixin],

  components: {
    CreditorLimitsMenu
  },

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    empty: {
      type: Boolean,
      default: false
    },
    initiated: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    expanded: []
  }),

  computed: {
    ...mapState(NAMESPACE, [
      'debtors',
      'loadingDebtors',
      'loadingDetails',
      'creditorLimits',
      'listError',
      'detailsError'
    ]),

    tableHeaders() {
      return this.isFactoring
        ? [
            { text: this.$t('status'), value: 'status', sortable: false },
            {
              text: this.$t('companyOrName'),
              value: 'name',
              sortMobile: true,
              alignLeft: true
            },
            {
              text: this.$t('debtors.customerId'),
              subText: this.hasAdminView ? this.$t('debtors.debtorType') : '',
              value: 'customNumber',
              sortable: false
            },
            {
              text: this.$t('debtors.totalInvoiceVolume'),
              subText: this.$t('debtors.thereofLeftover'),
              value: 'invoiceVolume',
              sortable: false
            },
            {
              text: this.$t('debtors.purchased'),
              subText: this.$t('debtors.thereofLeftover'),
              value: 'purchasedInvoiceVolume',
              sortable: false
            },
            {
              text: this.$t('debtors.notPurchased'),
              subText: this.$t('debtors.thereofLeftover'),
              value: 'notPurchasedInvoiceVolume',
              sortable: false
            },
            ...(this.permissions.debtors.showLimit
              ? [
                  {
                    text: this.$t('debtors.totalCreditLimit'),
                    subText: this.$t('debtors.thereofAvailable'),
                    value: 'approvedLimit',
                    sortMobile: true
                  }
                ]
              : []),
            {
              text: null,
              value: 'data-table-expand',
              sortable: false,
              width: '70px'
            }
          ]
        : [
            { text: this.$t('status'), value: 'status', sortable: false },
            {
              text: this.$t('companyOrName'),
              value: 'name',
              sortMobile: true,
              alignLeft: true
            },
            {
              text: this.$t('debtors.customerId'),
              subText: this.hasAdminView ? this.$t('debtors.debtorType') : '',
              value: 'customNumber',
              sortable: false
            },
            {
              text: this.$t('debtors.totalInvoiceVolume'),
              value: 'invoiceVolume',
              sortable: false
            },
            {
              text: this.$t('debtors.thereofLeftover'),
              value: 'openInvoiceVolume',
              sortable: false
            },
            {
              text: null,
              value: 'data-table-expand',
              sortable: false,
              width: '70px'
            }
          ];
    }
  },

  watch: {
    loadingDebtors() {
      this.updateExpanded();
    },
    loadingDetails() {
      this.updateExpanded();
    }
  },

  methods: {
    setDetailedSubParams({ locationId, debtorId }) {
      const idParam = `${debtorId},${locationId}`;
      if (idParam === this.$route.params.id) {
        this.$router.push({
          params: { id: undefined, type: undefined },
          query: { ...this.$route.query }
        });
        return;
      }

      this.$router.push({
        params: { id: idParam, type: 'sub' },
        query: { ...this.$route.query }
      });
    },

    updateExpanded() {
      if (
        this.loadingDebtors ||
        this.loadingDetails ||
        !this.$route.params.id ||
        this.$route.params.type !== 'sub' ||
        this.expanded.length > 0
      ) {
        return;
      }

      const detailedDebtor = this.tableData.find(
        (item) => item.id === this.$route.params.id.split(',')[0]
      );

      if (detailedDebtor) {
        this.expanded.push(detailedDebtor);
      }
    },

    formatDate(date) {
      return formatDate(date);
    }
  }
};
</script>
