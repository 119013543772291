<template>
  <div>
    <app-dialog
      v-model="open"
      title="debtors.editPayment.editDataFor"
      primary-action-text="navigation.save"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      @submit="onSubmit"
    >
      <ValidationObserver ref="form" tag="form">
        <v-container class="pa-0">
          <app-alert v-if="error" class="mb-4">
            {{ $t('debtors.editDebtorError') }}
          </app-alert>

          <v-row no-gutters>
            <v-col>
              <div class="semi-bold">{{ debtor.name }}</div>
              <span v-if="debtor.address.streetAndHouse">
                {{ debtor.address.streetAndHouse }},&#32;
              </span>
              <span>{{ debtor.address.zip }} {{ debtor.address.city }}</span>
            </v-col>
          </v-row>

          <app-input-row dense label="debtors.paymentMethod" class="mt-4 mb-4">
            <app-select v-model="formData.paymentMethod" :items="paymentMethodItems" required />
          </app-input-row>

          <template v-if="requireSepaData">
            <div class="caption text-uppercase grey--text semi-bold mt-8 d-flex">
              {{ $t('debtors.editPayment.sepaData') }}
            </div>

            <app-input-row dense label="accountOwner">
              <app-text-field
                v-model="formData.sepa.accountOwner"
                required
                name="accountOwner"
                placeholder="companyOrName"
              />
            </app-input-row>

            <app-input-row dense label="iban">
              <app-text-field
                v-model="formData.sepa.iban"
                placeholder="DEXX XX XXXXXXXX"
                name="iban"
                rules="required|iban"
              />
            </app-input-row>

            <app-input-row dense class="mt-6">
              <app-checkbox
                v-model="confirmedSepa"
                label="permanentDebts.confirmSepa"
                hide-details
                required
              />
              <a
                class="subtitle-1 text-left d-block ml-8"
                :href="product.documents.sepa.template.path"
                target="_blank"
              >
                ({{ product.documents.sepa.template.title }})
              </a>
            </app-input-row>
          </template>
        </v-container>
      </ValidationObserver>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      secondary-action-text="navigation.backToDebtor"
      title="debtors.editPayment.confirmed"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import filterChangedProperties from '@/helper/filterChangedProperties';
import { NAMESPACE } from '@/modules/Debtors/store';
import { PAYMENT_METHODS, PaymentMethod } from '@/modules/Debtors/statics/paymentMethod';
import { mapActions } from 'vuex';

export default {
  name: 'EditPaymentData',

  mixins: [ShowDialogMixin],

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      formData: this.createFormData(),
      oldPaymentData: this.createFormData(),
      confirmedSepa: false
    };
  },

  computed: {
    paymentMethodItems() {
      return PAYMENT_METHODS.map((paymentMethod) => ({
        value: paymentMethod,
        label: this.$t(`debtors.paymentMethods.${paymentMethod}`)
      }));
    },
    requireSepaData() {
      return this.formData.paymentMethod === PaymentMethod.SEPA_DIRECT_DEBIT;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['editDebtor']),

    createFormData() {
      return {
        paymentMethod: this.debtor.paymentMethod ?? PAYMENT_METHODS[0],
        sepa: {
          accountOwner: this.debtor.sepaMandate?.accountOwner ?? '',
          iban: this.debtor.sepaMandate?.iban ?? ''
        }
      };
    },

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      const newPaymentData = {
        paymentMethod: this.formData.paymentMethod,
        ...(this.requireSepaData
          ? {
              sepa: {
                ...this.formData.sepa
              }
            }
          : {})
      };

      const payload = filterChangedProperties(newPaymentData, this.oldPaymentData);

      if (Object.keys(payload).length === 0) {
        this.submitted = true;
        return;
      }

      this.loading = true;

      const { error } = await this.editDebtor({ debtorIdentifier: this.debtor.id, payload });

      this.loading = false;
      if (error) {
        this.error = true;
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
