<template>
  <app-autocomplete
    filter
    :items="items"
    :label="$t('debtors.customDebtorNumber')"
    label-icon="icon-hash"
    hide-details
    multiple
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { NAMESPACE } from '@/modules/Debtors/store';

export default {
  name: 'CustomDebtorNumberFilter',

  computed: mapState(NAMESPACE, { items: 'customDebtorNumbers' }),

  methods: mapActions(NAMESPACE, ['fetchFilterCustomDebtorNumber']),

  created() {
    return this.fetchFilterCustomDebtorNumber();
  }
};
</script>
