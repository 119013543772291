var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":_vm.noGutters}},[_c('v-col',{staticClass:"subtitle text-uppercase"},[_vm._v(" "+_vm._s(_vm.filterPurchased ? _vm.$t('debtors.showPurchasedInvoicesInVoucherList') : _vm.$t('debtors.showInvoicesInVoucherList'))+" ")])],1),_c('v-row',{attrs:{"no-gutters":_vm.noGutters}},_vm._l((_vm.linksToVoucherList),function(option,index){return _c('v-col',{key:index,staticClass:"mr-4",attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":{
          name: _vm.routeName.VOUCHERS,
          query: {
            location: _vm.locationNames,
            status: option.status,
            purchaseStatus: option.purchaseStatus
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t(option.title))+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }