<template>
  <div>
    <location-upload-dialog v-model="open" :debtor="debtor" @success="finishUpload" />

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVouchers"
      secondary-action-text="navigation.toDebtor"
      @submit="hideAfterRedirect"
      @close="hideAfterSuccess"
    >
      <h3 slot="title" class="semi-bold">
        <v-icon style="font-size: 5rem" color="secondary">mdi-check-bold</v-icon>
      </h3>
      <p>{{ $t('upload.uploadCompleted') }}</p>
    </app-dialog>
  </div>
</template>

<script>
import LocationUploadDialog from '@/modules/Debtors/components/LocationUploadDialog';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { NAMESPACE } from '@/modules/Unprocessed/store';
import { mapActions } from 'vuex';

export default {
  name: 'LocationInvoiceUploadDialog',

  mixins: [ShowDialogMixin],

  components: { LocationUploadDialog },

  props: {
    debtor: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchUnprocessedList']),

    resetDialog() {
      this.invoices = [];
      this.resetConfirmation();
    },

    async finishUpload() {
      this.submitted = true;
    },

    hideAfterSuccess() {
      this.submitted = false;
      this.$emit('input', false);
    },

    hideAfterRedirect() {
      this.hideAfterSuccess();
      this.$router.push({ name: this.routeName.UNPROCESSED });
    }
  }
};
</script>
