<template>
  <div>
    <app-dialog
      v-model="open"
      primary-action-text="navigation.yesDelete"
      secondary-action-text="navigation.noCancel"
      title="debtors.deletePostAddress"
      :loading="loading"
      @submit="submit"
    >
      <p>{{ $t('debtors.deletePostAddressText') }}</p>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toDebtor"
      title="debtors.addressDeleted"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'EditPostAddressDialog',

  mixins: [ShowDialogMixin],

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['deleteLocationPostAddress']),

    async submit() {
      this.loading = true;

      const { error } = await this.deleteLocationPostAddress(this.debtor.id);

      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
