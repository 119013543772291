<template>
  <v-container>
    <v-row>
      <v-col>
        <v-list class="pa-0">
          <v-list-item
            v-for="location in details.locations"
            :key="location.id"
            class="px-0 mb-6"
            :to="getLocationRoute({ id: location.id, debtorId: details.id, name: location.name })"
          >
            <v-list-item-content class="py-0">
              <v-list-item-title class="semi-bold">
                {{ location.name }}
              </v-list-item-title>
              <template v-if="location.address.streetAndHouse">
                {{ location.address.streetAndHouse }},&#32;
              </template>
              {{ location.address.zip }} {{ location.address.city }}
              <div class="overline grey--text">
                {{ $t('location') }}
              </div>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon size="1.2rem">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row v-if="details.isBusiness">
      <v-col>
        <app-btn
          v-if="permissions.debtorDetails.addLocation"
          @click="setQueryParam(queryParam.ACTION, queryParam.ADD_LOCATION)"
        >
          {{ $t('debtors.addLocation') }}
        </app-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TabDebtorLocations',

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
