<template>
  <div>
    <app-dialog
      v-model="open"
      title="debtors.editDebtor"
      primary-action-text="navigation.save"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      @submit="onSubmit"
    >
      <ValidationObserver ref="form" tag="form">
        <v-container class="pa-0">
          <app-alert v-if="error" class="mb-4">
            {{ $t('debtors.editDebtorError') }}
          </app-alert>

          <v-row>
            <template v-if="debtor.isBusiness">
              <v-col cols="12">
                <app-text-field v-model="formData.business.name" label="company" required />
              </v-col>
            </template>

            <template v-else>
              <v-col cols="6">
                <app-text-field v-model="formData.customer.firstName" label="firstName" required />
              </v-col>

              <v-col cols="6">
                <app-text-field v-model="formData.customer.lastName" label="lastName" required />
              </v-col>
            </template>

            <v-col cols="8">
              <app-text-field
                v-model="formData.limitAddress.street"
                label="street"
                :required="!isAdmin"
              />
            </v-col>

            <v-col cols="4">
              <app-text-field
                v-model="formData.limitAddress.house"
                label="house"
                :required="!isAdmin"
              />
            </v-col>

            <v-col cols="4">
              <app-text-field
                v-model="formData.limitAddress.zip"
                label="zipCode"
                rules="required|numeric|length: 5"
              />
            </v-col>

            <v-col cols="8">
              <app-text-field v-model="formData.limitAddress.city" label="city" required />
            </v-col>

            <v-col cols="12">
              <v-expansion-panels accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="caption text-uppercase grey--text semi-bold">
                    {{ $t('sepaDirectDebitMandate') }}
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="py-1">
                    <v-row justify="center">
                      <v-col cols="12">
                        <app-text-field
                          v-model="formData.sepa.accountOwner"
                          :required="hasEnteredSepaData"
                          label="accountOwner"
                        />
                      </v-col>

                      <v-col cols="12">
                        <app-text-field
                          v-model="formData.sepa.iban"
                          label="iban"
                          :required="hasEnteredSepaData"
                          rules="iban"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </ValidationObserver>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      secondary-action-text="navigation.backToDebtor"
      title="debtors.editedDebtor"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import filterChangedProperties from '@/helper/filterChangedProperties';
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapActions } from 'vuex';

export default {
  name: 'EditDebtorDialog',

  mixins: [ShowDialogMixin],

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      formData: this.createFormData(),
      oldDebtorData: this.createFormData()
    };
  },

  computed: {
    hasEnteredSepaData() {
      return Object.values(this.formData.sepa).some((value) => !!value);
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['editDebtor']),

    // eslint-disable-next-line sonarjs/cognitive-complexity
    createFormData() {
      return {
        ...(this.debtor.isBusiness
          ? {
              business: {
                name: this.debtor.name ?? ''
              }
            }
          : {
              customer: {
                firstName: this.debtor.firstName ?? '',
                lastName: this.debtor.lastName ?? ''
              }
            }),
        limitAddress: {
          street: this.debtor.address.street ?? '',
          house: this.debtor.address.house ?? '',
          city: this.debtor.address.city ?? '',
          zip: this.debtor.address.zip ?? ''
        },
        delivery: {
          type: this.debtor.deliveryType ?? '',
          email: this.debtor.email ?? ''
        },
        contact: {
          telephone: this.debtor.phone ?? ''
        },
        sepa: {
          accountOwner: this.debtor.sepaMandate?.accountOwner ?? '',
          iban: this.debtor.sepaMandate?.iban ?? ''
        }
      };
    },

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      let payload = filterChangedProperties(this.formData, this.oldDebtorData);

      if (Object.keys(payload).length === 0) {
        this.submitted = true;
        return;
      }

      this.loading = true;

      const { error } = await this.editDebtor({ debtorIdentifier: this.debtor.id, payload });

      this.loading = false;
      if (error) {
        this.error = true;
        return;
      }

      this.submitted = true;
    }
  }
};
</script>

<style scoped>
>>> .v-expansion-panel-header,
>>> .v-expansion-panel-content__wrap {
  padding: 0;
  min-height: unset;
}

.v-expansion-panels >>> .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: var(--c-primary);
}
</style>
