<template>
  <child-side-drawer-container>
    <template #header>
      <v-row>
        <v-col cols="12">
          <h3>{{ details.debtor.name }}</h3>
          <span v-if="hasAdminView" class="caption text--muted">
            {{ details.debtor.type | formatDebtorType }}
          </span>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col v-if="isFactoring" cols="auto">
          <h3>{{ details.availableLimit | formatCurrency }}</h3>
          <span class="caption text--muted">{{ $t('debtors.availableLimit') }}</span>
        </v-col>
        <v-col cols="auto" :class="{ 'text-right': isFactoring }">
          <h3>
            {{ details.debtor.leftoverAmount | formatCurrency }}
          </h3>
          <span class="caption text--muted">{{ $t('debtors.debt') }}</span>
        </v-col>
      </v-row>
    </template>

    <v-row class="mt-2" no-gutters>
      <v-col cols="12">
        <h3 class="font-weight-bold">
          {{ details.name }}
        </h3>
        <div class="caption text--muted">
          {{ $t('location') }}
        </div>
      </v-col>
    </v-row>
    <v-row class="caption" dense>
      <v-col cols="auto">{{ $t('debtor') }}:</v-col>
      <v-col class="d-flex align-center">
        <app-link-btn :title="details.debtor.name" :to="getDebtorRoute(details.debtor)" />
      </v-col>
    </v-row>

    <v-row class="mt-4" justify="space-between" align="stretch">
      <v-col>
        <h3>
          <app-status-chip class="d-inline-flex" :value="details.status" />
        </h3>
        <div class="caption text--muted">{{ $t('status') }}</div>
      </v-col>
      <v-col>
        <h3>
          {{ details.customNumber || '-' }}
        </h3>
        <div class="caption text--muted">{{ $t('debtors.customDebtorNumber') }}</div>
      </v-col>
    </v-row>

    <app-action-buttons :actions="actions" />

    <app-tabs
      :key="details.id"
      v-model="activeTab"
      class="mt-8"
      :tabs="filterItemsWithFulfilledCondition(tabs)"
    >
      <component
        :is="filterItemsWithFulfilledCondition(tabs)[activeTab].component"
        class="mt-4"
        :details="details"
      />
    </app-tabs>

    <div v-if="hasAdminView" class="px-5">
      <v-divider style="margin-top: 3rem" />
      <v-row>
        <app-api-log :data="detailedDebtorRaw" api-name="debtor/{debtorId}/location/{locationId}" />
      </v-row>
    </div>
  </child-side-drawer-container>
</template>

<script>
import ChildSideDrawerContainer from '@/shared/components/ChildSideDrawerContainer';
import ProcessLog from '@/shared/components/ProcessLog';
import TabDebtorInvoices from '@/modules/Debtors/components/Details/TabDebtorInvoices';
import TabLocationInfo from '@/modules/Debtors/components/Details/TabLocationInfo';
import TabLocationPermanentDebts from '@/modules/Debtors/components/Details/TabLocationPermanentDebts';
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapState } from 'vuex';

export default {
  name: 'LocationDetails',

  components: {
    ChildSideDrawerContainer,
    ProcessLog
  },

  data: () => ({
    activeTab: 0
  }),

  props: {
    details: {
      type: Object,
      default: () => ({})
    },
    debtorActions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['processLogRaw', 'detailedDebtorRaw']),

    actions() {
      return [
        this.queryParam.EDIT_LOCATION,
        this.queryParam.UPLOAD_INVOICE,
        this.queryParam.CREATE_PERMANENT_DEBT,
        this.queryParam.ACTIVATE,
        this.queryParam.DEACTIVATE
      ].map((query) => this.debtorActions.find((action) => action.param === query));
    },
    tabs() {
      return [
        {
          id: 1,
          name: this.$t('info'),
          component: TabLocationInfo
        },
        {
          id: 2,
          name: this.$t('invoices'),
          component: TabDebtorInvoices
        },
        {
          id: 3,
          name: this.$t('permDebts'),
          component: TabLocationPermanentDebts,
          condition: this.permissions.debtorDetails.tabPermanentDebts
        }
      ];
    }
  },

  watch: {
    'details.id'() {
      this.activeTab = 0;
    }
  }
};
</script>
