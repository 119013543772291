<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      primary-action-text="navigation.request"
      secondary-action-text="navigation.cancel"
      title="debtors.limitIncrease.increaseFor"
      :disabled="!amount || failed"
      :loading="loading"
      @submit="submitLimitIncreaseRequest"
    >
      <v-row no-gutters>
        <v-col>
          <div class="semi-bold">{{ debtor.name }}</div>
          <span v-if="debtor.address.streetAndHouse">
            {{ debtor.address.streetAndHouse }},&#32;
          </span>
          <span>{{ debtor.address.zip }} {{ debtor.address.city }}</span>
        </v-col>
      </v-row>

      <v-row no-gutters justify="space-between" class="mx-3" style="margin-top: 3.75rem">
        <v-col cols="auto">{{ $t('debtors.limitIncrease.currentLimit') }}:</v-col>
        <v-col class="text-right">
          {{ debtor.limit.approvedLimit | formatCurrency }}
        </v-col>
        <v-col cols="12" class="caption text--muted text-right">
          {{ $t('debtors.since') }}: {{ debtor.limit.limitUpdated | formatDate }}
        </v-col>
      </v-row>

      <app-input-row class="mt-6">
        <template #label>
          <div class="d-flex">
            {{ $t('debtors.limitIncrease.wantedLimit') }}:
            <span class="text--small primary--text">*</span>
          </div>
        </template>
        <app-number-field
          v-model="amount"
          placeholder="0,00"
          prepend-inner-icon="$iconEuro"
          name="debtors.limitIncrease.wantedLimit"
          :rules="`required|min_currency:${debtor.limit.approvedLimit}`"
          align-right
        />
      </app-input-row>

      <v-row class="mt-6">
        <v-col cols="12" class="caption primary--text d-flex">
          <span class="text--small">*</span>
          <span>{{ $t('debtors.limitIncrease.durationInfo') }}</span>
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="debtors.limitRequestSent"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('debtors.limitIncrease.sent') }}</p>
    </app-dialog>
  </ValidationObserver>
</template>

<script>
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'IncreaseLimitDialog',

  mixins: [ShowDialogMixin],

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    amount: ''
  }),

  computed: {
    debtorIdentifier() {
      return this.$route.params.id;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['requestLimitIncrease']),

    async submitLimitIncreaseRequest() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      this.loading = true;
      const payload = {
        debtorIdentifier: this.debtorIdentifier,
        data: {
          amount: this.amount
        }
      };

      const { error } = await this.requestLimitIncrease(payload);
      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
      this.amount = null;
      this.$refs.form?.reset();
    }
  }
};
</script>
