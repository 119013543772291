<template>
  <v-container class="px-0">
    <div v-if="hasAdminView" class="mb-10">
      <app-labeled-data
        text-right
        no-gutters
        :label="details.isBusiness ? $t('company') : $t('name')"
        :value="details.name"
      />

      <app-labeled-data
        text-right
        no-gutters
        label="street"
        :value="details.address.streetAndHouse"
      />

      <app-labeled-data
        text-right
        no-gutters
        :label="`${$t('zip')} / ${$t('city')}`"
        :value="`${details.address.zip} ${details.address.city}`"
      />

      <app-labeled-data v-if="!details.isBusiness" text-right no-gutters label="dateOfBirth">
        <span v-if="details.dateOfBirth">{{ details.dateOfBirth | formatDate }}</span>
        <span v-else>-</span>
      </app-labeled-data>
    </div>
    <div v-if="permissions.debtorDetails.showPaymentData" class="mb-10">
      <v-row no-gutters>
        <v-col class="subtitle text-uppercase">{{ $t('debtors.paymentData') }}</v-col>
      </v-row>

      <app-labeled-data
        text-right
        no-gutters
        label="debtors.paymentMethod"
        :value="$t(`debtors.paymentMethods.${details.paymentMethod}`)"
      />

      <v-expansion-panels accordion flat class="sepa-mandate__panel mb-10">
        <v-expansion-panel :disabled="!details.sepaMandate || !permissions.debtorDetails.showSepa">
          <v-expansion-panel-header class="body-1">
            <app-labeled-data
              class="sepa-mandate__label"
              text-right
              no-gutters
              label="debtors.sepaMandate"
              :value="details.sepaMandate ? $t('debtors.sepaRecorded') : '-'"
            />
          </v-expansion-panel-header>

          <v-expansion-panel-content
            v-if="details.sepaMandate && permissions.debtorDetails.showSepa"
          >
            <app-alert color="primary" :icon="false">
              <app-labeled-data
                no-gutters
                text-right
                label="accountOwner"
                :value="details.sepaMandate.accountOwner"
              />
              <app-labeled-data
                no-gutters
                text-right
                label="iban"
                :value="details.sepaMandate.iban"
              />
              <app-labeled-data
                no-gutters
                text-right
                label="referenceNumber"
                :value="details.sepaMandate.referenceNumber"
              />
              <app-labeled-data no-gutters text-right label="debtors.updatedOn">
                {{ details.sepaMandate.date | formatDate }}
              </app-labeled-data>
              <app-labeled-data no-gutters text-right label="debtors.validUntil">
                {{ details.sepaMandate.expirationDate | formatDate }}
              </app-labeled-data>
            </app-alert>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-row no-gutters>
      <v-col cols="12" class="caption text-uppercase grey--text semi-bold">
        {{ $t('lastActivities') }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <history :data="history" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import History from '@/shared/components/History';
import HistoryMixin from '@/mixins/HistoryMixin';

export default {
  name: 'TabDebtorInfo',

  mixins: [HistoryMixin],

  components: {
    History
  },

  props: {
    details: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
// align sepa-mandate and history panel icons with labeled data
::v-deep .v-expansion-panel-header__icon {
  margin-left: 8px;
  margin-right: -6px;
}

.sepa-mandate__panel {
  ::v-deep .v-expansion-panel-header,
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 4px 0;
    min-height: unset;
  }

  ::v-deep .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: var(--c-primary);
  }
}

.sepa-mandate__label {
  color: var(--c-text);
  margin-bottom: 0 !important;
}
</style>
