<template>
  <ValidationObserver ref="form" tag="form">
    <app-dialog
      v-model="open"
      primary-action-text="navigation.save"
      secondary-action-text="navigation.cancel"
      title="debtors.editPostAddress"
      :loading="loading"
      @submit="submit"
    >
      <p>{{ $t('debtors.editPostAddressText') }}</p>
      <v-row justify="center" class="mt-4">
        <v-col cols="auto">
          <app-radio-group v-model="addressType" :items="addressTypeItems" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <app-text-field v-model="address.name" label="debtors.recipient" required />
        </v-col>
        <template v-if="selectedHomeAddress">
          <v-col cols="8">
            <app-text-field v-model="address.street" label="street" :required="!isAdmin" />
          </v-col>
          <v-col cols="4">
            <app-text-field v-model="address.house" label="house" :required="!isAdmin" />
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12">
            <app-text-field v-model="address.postBox" label="poBox" required />
          </v-col>
        </template>
      </v-row>

      <v-row>
        <v-col cols="4">
          <app-text-field v-model="address.zip" label="zip" rules="required|numeric|length: 5" />
        </v-col>
        <v-col cols="8">
          <app-text-field v-model="address.city" label="city" required />
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toDebtor"
      title="addresses.addressAdded"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

const HOME_ADDRESS = 'homeAddress';
const POST_BOX = 'postBox';

export default {
  name: 'EditPostAddressDialog',

  mixins: [ShowDialogMixin],

  data() {
    return {
      addressType:
        this.debtor.postAddress && this.debtor.postAddress.postBox ? POST_BOX : HOME_ADDRESS,
      address: {
        name: this.debtor.name,
        street: '',
        house: '',
        city: '',
        zip: '',
        postBox: ''
      }
    };
  },

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  computed: {
    addressTypeItems() {
      return [
        {
          label: this.$t('debtors.homeAddress'),
          value: HOME_ADDRESS
        },
        {
          label: this.$t('debtors.postBox'),
          value: POST_BOX
        }
      ];
    },
    selectedHomeAddress() {
      return this.addressType === HOME_ADDRESS;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['editLocationPostAddress']),

    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const payload = {
        identifier: this.debtor.id,
        address: {
          ...(this.selectedHomeAddress
            ? {
                name: this.address.name,
                street: this.address.street,
                house: this.address.house,
                houseExtension: '',
                zip: this.address.zip,
                city: this.address.city,
                postboxNumber: ''
              }
            : {
                name: this.address.name,
                street: '',
                house: '',
                houseExtension: '',
                zip: this.address.zip,
                city: this.address.city,
                postboxNumber: this.address.postBox
              })
        }
      };

      const { error } = await this.editLocationPostAddress(payload);

      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
      this.$refs.form?.reset();
    }
  },

  created() {
    if (this.debtor.postAddress) {
      this.address = {
        ...this.debtor.postAddress,
        name: this.debtor.postAddress.name ?? this.debtor.name
      };
    }
  }
};
</script>
