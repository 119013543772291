<template>
  <app-select
    filter
    :items="states"
    :label="$t('debtors.debtorType')"
    label-icon="icon-target-client"
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'DebtorTypeSelect',

  computed: {
    states() {
      return Object.keys(this.$t('debtors.debtorTypes')).map((type) => ({
        label: this.$t(`debtors.debtorTypes.${type}`),
        value: type
      }));
    }
  }
};
</script>
