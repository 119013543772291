<template>
  <div>
    <v-row no-gutters class="mx-n6 mt-md-n10 mt-n5">
      <v-col class="pa-2 px-5">
        <app-text-btn
          icon-size="10"
          prepend-icon="icon-chevron-left"
          title="upload.uploadVouchers"
          @click="$emit('cancel')"
        />
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>

    <p class="mt-5">{{ $t('upload.templatesForDownload') }}</p>

    <v-row class="mt-1">
      <v-col
        v-for="(item, index) in items"
        :key="index"
        cols="auto"
        class="d-flex flex-column caption"
      >
        <v-img :src="item.icon" max-width="100" />
        <span class="mt-2">{{ item.title }}</span>
        <app-link-btn :href="item.path" download title="download" variant="muted" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'VoucherTemplates',

  data() {
    return {
      items: [
        {
          title: 'Word',
          icon: require('@/assets/icon-word.png'),
          path: '/assets/templates/Muster_Debitorenrechnung.docx'
        },
        {
          title: 'Excel',
          icon: require('@/assets/icon-excel.png'),
          path: '/assets/templates/Muster_Debitorenrechnung.xlsx'
        }
      ]
    };
  }
};
</script>
