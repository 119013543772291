<template>
  <v-container>
    <template v-if="details.vouchers.length > 0">
      <links-to-voucher-list :details="details" />

      <v-row class="mt-10">
        <v-col class="subtitle text-uppercase">{{ $t('debtors.lastInvoices') }}</v-col>
      </v-row>
      <v-row v-for="voucher in details.vouchers" :key="voucher.id" class="mb-4">
        <v-col class="pr-2">
          <router-link :to="getVoucherRoute(voucher)">
            {{ voucher.numberToShow || '-' }}
          </router-link>
        </v-col>
        <v-col class="pr-2">
          {{ voucher.statusToShow | formatVoucherStatusToShow }}
          <div class="overline text--muted">{{ voucher.subStatus | formatSubStatus }}</div>
        </v-col>
        <v-col class="text-right">{{ voucher.amount | formatCurrency }}</v-col>
      </v-row>
    </template>

    <v-row v-else>
      <v-col>
        {{ $t('debtors.noInvoices') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LinksToVoucherList from '@/shared/components/LinksToVoucherList';

export default {
  name: 'TabDebtorInvoices',

  components: { LinksToVoucherList },

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style scoped>
.v-col {
  justify-content: flex-start;
}

#app.v-application .overline {
  line-height: 10px !important;
}
</style>
