<template>
  <v-container fluid pa-0>
    <add-btn v-if="permissions.debtors.createDebtor" @click="openCreateDebtorDialog" />
    <debtors-filter />
    <debtor-assignments-alert />
    <debtors-table
      :initiated="startedInitialFetch"
      :table-data="debtors"
      :count="debtorsCount"
      :empty="debtorsEmpty"
      :class="{ 'content--control-width': true, 'content--shrinked': showDetailsDrawer }"
    />

    <app-side-drawer :show="showDetailsDrawer" :error="detailsError" :report-data="reportData">
      <component
        :is="
          $route.params.type === 'sub' && detailedDebtor.isLocation
            ? 'location-details'
            : 'debtor-details'
        "
        v-if="fetchedDetailedDebtor"
        :details="detailedDebtor"
        :debtor-actions="actions"
      />
    </app-side-drawer>

    <component
      :is="selectedAction.component"
      v-if="fetchedDetailedDebtor"
      v-model="showActionDialog"
      :debtor="detailedDebtor"
      @success="reloadDetails($route.query)"
    />
  </v-container>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin';
import AddBtn from '@/shared/components/AddBtn';
import AddLocationDialog from '@/modules/Debtors/components/Actions/Debtor/AddLocationDialog';
import AppSideDrawer from '@/shared/components/AppSideDrawer';
import DebtorAssignmentsAlert from '@/modules/Debtors/components/DebtorAssignmentsAlert';
import DebtorDetails from '@/modules/Debtors/components/Details/DebtorDetails';
import DebtorsFilter from '@/modules/Debtors/components/Filter/DebtorsFilter';
import DebtorsTable from '@/modules/Debtors/components/DebtorsTable';
import DeletePostAddressDialog from '@/modules/Debtors/components/Actions/Location/DeletePostAddressDialog';
import EditDebtorDialog from '@/modules/Debtors/components/Actions/Debtor/EditDebtorDialog';
import EditDebtorStatusDialog from '@/modules/Debtors/components/Actions/EditDebtorStatusDialog';
import EditLocationDialog from '@/modules/Debtors/components/Actions/Location/EditLocationDialog';
import EditPaymentDataDialog from '@/modules/Debtors/components/Actions/Debtor/EditPaymentDataDialog';
import EditPostAddressDialog from '@/modules/Debtors/components/Actions/Location/EditPostAddressDialog';
import IncreaseLimitDialog from '@/modules/Debtors/components/Actions/Debtor/IncreaseLimitDialog';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import LocationDetails from '@/modules/Debtors/components/Details/LocationDetails';
import LocationInvoiceUploadDialog from '@/modules/Debtors/components/Actions/Location/LocationInvoiceUploadDialog';
import RefreshLimitDialog from '@/modules/Debtors/components/Actions/Debtor/RefreshLimitDialog';
import SolvencyCheckDialog from '@/modules/Debtors/components/Actions/Debtor/SolvencyCheckDialog';
import { DebtorStatus, DEBTOR_STATE_GROUPS } from '@/modules/Debtors/statics/debtorStatus';
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import { QueryParam } from '@/statics/queryParam';

export default {
  name: 'Debtors',

  components: {
    AddBtn,
    AppSideDrawer,
    DebtorAssignmentsAlert,
    DebtorDetails,
    DebtorsFilter,
    DebtorsTable,
    LocationDetails
  },

  mixins: [ActionMixin, ListFilterMixin],

  data: () => ({
    filteredTableData: [],
    startedInitialFetch: false,
    queryValues: [
      QueryParam.PAGE,
      QueryParam.ITEMS_PER_PAGE,
      QueryParam.SORT_BY,
      QueryParam.SORT_DESC,
      'limit',
      'customNumber',
      'debtor',
      'status',
      'type'
    ],
    defaultSorting: {
      sortBy: ['name'],
      sortDesc: [false]
    },
    defaultFilter: {
      status: [DebtorStatus.ACTIVE]
    }
  }),

  computed: {
    ...mapState(NAMESPACE, [
      'debtors',
      'debtorsCount',
      'debtorsEmpty',
      'detailedDebtor',
      'loadingDebtors',
      'loadingDetails',
      'detailsError'
    ]),
    ...mapGetters(NAMESPACE, ['identifiersByNames']),
    actions() {
      return [
        {
          param: this.queryParam.EDIT_DEBTOR,
          component: EditDebtorDialog,
          icon: 'icon-edit-debtor',
          text: this.$t('debtors.editDebtor'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.EDIT_DEBTOR),
          permission: this.detailedDebtor.permissions.editDebtor,
          condition: this.isAdmin && this.permissions.debtorDetails.editDebtor
        },
        {
          param: this.queryParam.EDIT_PAYMENT_DATA,
          component: EditPaymentDataDialog,
          icon: 'icon-sepa',
          text: this.$t('debtors.editPaymentData'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.EDIT_PAYMENT_DATA),
          permission: this.detailedDebtor.permissions.editPaymentData,
          condition: this.permissions.debtorDetails.editPaymentData
        },
        {
          param: this.queryParam.REQUEST_LIMIT_INCREASE,
          component: IncreaseLimitDialog,
          icon: 'icon-increase-limit',
          text: this.$t('debtors.increaseLimitRequest'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.REQUEST_LIMIT_INCREASE),
          permission:
            this.detailedDebtor.permissions.requestLimitIncrease &&
            !this.detailedDebtor.limit.limitRequestActive,
          condition:
            this.isFactoring &&
            !this.detailedDebtor.permissions.refreshLimit &&
            this.permissions.debtorDetails.increaseLimit
        },
        {
          param: this.queryParam.REFRESH_LIMIT,
          component: RefreshLimitDialog,
          icon: 'icon-refresh-limit',
          text: this.$t('debtors.refreshLimit'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.REFRESH_LIMIT),
          permission: this.detailedDebtor.permissions.refreshLimit,
          condition:
            this.isFactoring &&
            this.detailedDebtor.permissions.refreshLimit &&
            this.permissions.debtorDetails.refreshLimit
        },
        {
          param: this.queryParam.ACTIVATE,
          component: EditDebtorStatusDialog,
          icon: 'icon-activate-debtor',
          text: this.detailedDebtor.isLocation
            ? this.$t(`debtors.editDebtorStatus.activateLocation`)
            : this.$t(`debtors.editDebtorStatus.activateDebtor`),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.ACTIVATE),
          permission: this.detailedDebtor.permissions.activateLocation,
          condition:
            this.isAdmin &&
            this.detailedDebtor.isInactive &&
            (this.detailedDebtor.isLocation
              ? this.permissions.debtorDetails.activateLocation
              : this.permissions.debtorDetails.activateDebtor)
        },
        {
          param: this.queryParam.DEACTIVATE,
          component: EditDebtorStatusDialog,
          icon: 'icon-deactivate-debtor',
          text: this.detailedDebtor.isLocation
            ? this.$t(`debtors.editDebtorStatus.deactivateLocation`)
            : this.$t(`debtors.editDebtorStatus.deactivateDebtor`),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.DEACTIVATE),
          condition:
            this.isAdmin &&
            !this.detailedDebtor.isInactive &&
            (this.detailedDebtor.isLocation
              ? this.permissions.debtorDetails.deactivateLocation
              : this.permissions.debtorDetails.deactivateDebtor)
        },
        {
          param: this.queryParam.CHECK_SOLVENCY,
          component: SolvencyCheckDialog,
          icon: 'icon-check-solvency',
          text: this.$t('debtors.checkSolvency'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.CHECK_SOLVENCY),
          permission: this.detailedDebtor.permissions.checkSolvency,
          condition: this.permissions.debtorDetails.checkSolvency
        },
        {
          param: this.queryParam.EDIT_LOCATION,
          component: EditLocationDialog,
          icon: 'icon-edit-debtor',
          text: this.$t('debtors.editDebtor'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.EDIT_LOCATION),
          permission: this.detailedDebtor.permissions.editLocation,
          condition: this.permissions.debtorDetails.editLocation
        },
        {
          param: this.queryParam.UPLOAD_INVOICE,
          component: LocationInvoiceUploadDialog,
          icon: 'icon-upload-invoice',
          text: this.$t('uploadInvoice'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.UPLOAD_INVOICE),
          permission: this.detailedDebtor.permissions.uploadInvoice,
          condition: this.permissions.debtorDetails.uploadInvoice
        },
        {
          param: this.queryParam.CREATE_PERMANENT_DEBT,
          icon: 'icon-permanent-debt',
          text: this.$t('permanentDebts.addPermDebt'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.CREATE_PERMANENT_DEBT),
          permission: this.detailedDebtor.permissions.createPermanentDebt,
          condition: this.permissions.debtorDetails.createPermanentDebt
        },
        {
          param: this.queryParam.EDIT_POST_ADDRESS,
          component: EditPostAddressDialog,
          permission: this.detailedDebtor.permissions.editPostAddress,
          condition: this.permissions.debtorDetails.editPostAddress
        },
        {
          param: this.queryParam.DELETE_POST_ADDRESS,
          component: DeletePostAddressDialog,
          permission: this.detailedDebtor.permissions.deletePostAddress,
          condition: this.permissions.debtorDetails.deletePostAddress
        },
        {
          param: this.queryParam.ADD_LOCATION,
          component: AddLocationDialog,
          permission: this.detailedDebtor.permissions.addLocation,
          condition: this.permissions.debtorDetails.addLocation
        }
      ];
    },
    fetchedDetailedDebtor() {
      return (
        this.startedInitialFetch &&
        !this.loadingDebtors &&
        !this.loadingDetails &&
        !!this.detailedDebtor &&
        (this.$route.params.type === 'sub'
          ? this.detailedDebtor.isLocation
          : !this.detailedDebtor.isLocation)
      );
    },
    showDetailsDrawer() {
      return this.$route.params.id !== undefined;
    },
    reportData() {
      if (!this.detailedDebtor) {
        return;
      }

      return this.detailedDebtor.isLocation
        ? `Versandadresse:
        * Identifier: ${this.detailedDebtor.id}
        * Name: ${this.detailedDebtor.name}

        Zugehöriger Kunde:
        * Identifier: ${this.detailedDebtor.debtor.id}
        * Name: ${this.detailedDebtor.debtor.name}`
        : `Kundendaten:
        * Identifier: ${this.detailedDebtor.id}
        * Name: ${this.detailedDebtor.name}
        * SAP-Nr.: ${this.detailedDebtor.sapNumber || '-'}`;
    }
  },

  methods: {
    ...mapMutations(NAMESPACE, { resetDetailedDebtor: 'RESET_DETAILED_DEBTOR' }),

    ...mapActions(NAMESPACE, [
      'fetchDebtors',
      'fetchDetailedDebtor',
      'fetchDetailedLocation',
      'fetchFilterDebtorNames'
    ]),

    async initDebtorData() {
      // the debtor name call must be done before loading debtors to enable deep links with debtor name filters
      // this should not delay detailed debtor though, to prevent mixing identifiers on route change

      this.updateDetailedDebtor();

      if (this.debtorSelection.length > 0) {
        await this.fetchFilterDebtorNames();
      } else {
        this.fetchFilterDebtorNames();
      }

      this.loadDebtors();
    },

    loadDebtors(query = this.defaultQuery) {
      this.fetchDebtors({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query)
      });
    },

    buildFilters() {
      let filters = {};

      if (this.debtorSelection.length > 0) {
        filters.identifier = this.identifiersByNames(this.debtorSelection);
      }

      if (this.statusSelection.length > 0) {
        filters.status = this.statusSelection.flatMap((state) => DEBTOR_STATE_GROUPS[state] ?? []);
      }

      if (this.customNumberSelection.length > 0) {
        filters.customNumber = this.customNumberSelection;
      }

      if (this.limitSelection.length > 0) {
        filters.limit = this.limitSelection[0];
      }

      if (this.typeSelection.length > 0) {
        filters.type = this.typeSelection[0];
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    },

    reloadDetails(query) {
      this.updateDetailedDebtor();
      this.loadDebtors(query);
    },

    async updateDetailedDebtor() {
      if (!this.$route.params.id && this.detailedDebtor) {
        return this.resetDetailedDebtor();
      }

      if (!this.$route.params.id) {
        return;
      }

      if (this.$route.params.type === 'sub') {
        const [debtorIdentifier, locationIdentifier] = this.$route.params.id.split(',');
        await this.fetchDetailedLocation({ debtorIdentifier, locationIdentifier });
        return;
      }

      await this.fetchDetailedDebtor(this.$route.params.id);
    }
  },

  watch: {
    '$route.params.id'() {
      this.updateDetailedDebtor();
    },
    '$route.query': {
      deep: true,
      handler(query) {
        if (!this.startedInitialFetch) {
          return;
        }

        this.updateTableQueries(query, this.queryValues, this.loadDebtors);
      }
    }
  },

  async created() {
    await this.initDebtorData();

    this.startedInitialFetch = true;

    this.$eventHub.$on('reload-debtors', () => this.loadDebtors(this.$route.query));
  },

  destroyed() {
    if (this.detailedDebtor) {
      this.resetDetailedDebtor();
    }

    this.$eventHub.$off('reload-debtors');
  }
};
</script>
