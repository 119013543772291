<template>
  <v-alert
    v-if="openDebtorAssignmentsCount > 0"
    border="left"
    class="py-3 px-5 mt-2"
    color="error"
    colored-border
    elevation="2"
  >
    {{ $tc('debtors.hasOpenDebtorAssignmentsCounted', openDebtorAssignmentsCount) }}

    <v-expansion-panels v-model="openedExpansionPanelIndex" accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="panel__header">
          <div class="header__content">
            {{ $t(showList ? 'debtors.hideDebtorsInReview' : 'debtors.showDebtorsInReview') }}
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="panel__content">
          <ul class="assignments-list">
            <li v-for="(debtorAddress, index) in openDebtorAssignmentsList" :key="index">
              <span class="semi-bold">{{ debtorAddress.name }},</span>
              {{ debtorAddress.streetAndHouse }}, {{ debtorAddress.zip }} {{ debtorAddress.city }}
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-alert>
</template>

<script>
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapState } from 'vuex';

export default {
  name: 'DebtorAssignmentsAlert',

  data: () => ({
    openedExpansionPanelIndex: undefined
  }),

  computed: {
    ...mapState(NAMESPACE, ['openDebtorAssignmentsList', 'openDebtorAssignmentsCount']),
    showList() {
      return this.openedExpansionPanelIndex !== undefined;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-alert__border {
  border-left-width: 5px;
  border-right-width: 0;
}

.panel__header {
  min-height: unset;
  margin-top: 16px;
  padding: 0;

  ::v-deep .v-expansion-panel-header__icon {
    align-self: baseline;
    margin-left: 0;

    .v-icon {
      color: var(--c-primary) !important;
    }
  }

  .header__content {
    @include caption-font-style;
    flex-grow: 0;
    text-transform: uppercase;
    color: var(--c-grey);
    font-weight: var(--font-weight-semi-bold);
  }
}

.panel__content {
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }

  .assignments-list {
    @include subtitle-1-font-style;
    list-style-type: none;
    padding: 0;

    > li {
      margin-top: 4px;

      &:first-of-type {
        margin-top: 12px;
      }
    }
  }
}
</style>
