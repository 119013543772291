<template>
  <div>
    <app-dialog
      v-if="!privacyPolicyConfirmed && creditorPermissions.checkSolvency"
      v-model="open"
      title="debtors.solvencyPrivacyPolicyTitle"
      primary-action-text="navigation.yesContinue"
      secondary-action-text="navigation.noCancel"
      @submit="onConfirmPrivacyPolicy"
    >
      <p v-html="$t('debtors.solvencyPrivacyPolicyText')"></p>
    </app-dialog>

    <app-dialog
      v-if="!privacyPolicyConfirmed && !creditorPermissions.checkSolvency"
      v-model="open"
      title="debtors.solvencyPrivacyPolicyTitleNoPermission"
      secondary-action-text="navigation.ok"
    >
      <p>{{ $t('debtors.solvencyPrivacyPolicyTextNoPermission') }}</p>
    </app-dialog>

    <app-dialog
      v-if="privacyPolicyConfirmed && creditorPermissions.checkSolvency"
      v-model="open"
      :loading="loading"
      :title="error ? 'debtors.checkSolvency' : null"
      :primary-action-text="error ? 'navigation.ok' : null"
      @submit="$emit('input', false)"
    >
      <div v-if="!error" class="text-center mb-12">
        <app-spinner />
        <h3 class="mt-2">{{ $t('debtors.checkingSolvency') }}</h3>
      </div>
      <app-alert v-else>{{ $t('debtors.checkDebtorError') }}</app-alert>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="debtors.solvencyDecision"
      @submit="hideAfterSuccess"
    >
      <v-row class="mx-6" justify="center">
        <v-col class="shrink">
          <icon-clients />
        </v-col>
        <v-col class="grow">
          <div class="semi-bold">{{ debtor.name }}</div>
          <span v-if="debtor.address.streetAndHouse">
            {{ debtor.address.streetAndHouse }},&#32;
          </span>
          <span>{{ debtor.address.zip }} {{ debtor.address.city }}</span>
        </v-col>
      </v-row>

      <v-row v-if="response" class="mt-6">
        <v-col class="d-flex justify-center">
          <img :src="response.img" :alt="$t('debtors.solvency')" />
        </v-col>
        <v-col>
          <div class="caption">{{ $t('debtors.solvency') }}</div>
          <div class="text-h2 d-flex align-center pt-2">
            {{ $t(response.solvency) }}
            <app-tooltip v-if="response.reasonCode">
              <template #text>
                <div class="caption pa-2">
                  {{ response.reasonCode }}
                </div>
              </template>
              <icon-info class="ml-4" />
            </app-tooltip>
          </div>
        </v-col>
      </v-row>
    </app-dialog>
  </div>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { NAMESPACE } from '@/modules/Debtors/store';
import { NAMESPACE as USER_NAMESPACE } from '@/modules/User/store';
import { mapActions, mapState } from 'vuex';
import { GREEN, RED, YELLOW } from '@/modules/Debtors/statics/solvencyRanges';
import { formatSolvencyReasonCode } from '@/helper/formatSolvencyReasonCode';

export default {
  name: 'SolvencyCheckDialog',

  mixins: [ShowDialogMixin],

  data: () => ({
    response: null,
    privacyPolicyConfirmed: false
  }),

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(USER_NAMESPACE, ['creditorPermissions']),
    debtorIdentifier() {
      return this.$route.params.id;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['checkSolvency']),

    mapResponseData(response) {
      if (response.color === GREEN) {
        return {
          img: require('@/modules/Debtors/assets/traffic-light-green.svg'),
          solvency: 'debtors.highSolvency'
        };
      }
      if (response.color === YELLOW) {
        return {
          img: require('@/modules/Debtors/assets/traffic-light-yellow.svg'),
          solvency: 'debtors.averageSolvency'
        };
      }
      if (response.color === RED) {
        return {
          img: require('@/modules/Debtors/assets/traffic-light-red.svg'),
          solvency: 'debtors.lowSolvency'
        };
      }
    },
    async onConfirmPrivacyPolicy() {
      this.privacyPolicyConfirmed = true;
      const { response, error } = await this.checkSolvency(this.debtorIdentifier);

      this.loading = false;
      if (error) {
        this.error = true;
        return;
      }
      this.response = {
        ...this.mapResponseData(response),
        reasonCode: formatSolvencyReasonCode(response.reasonCode, this.debtor.type)
      };

      this.submitted = true;
    }
  }
};
</script>
