<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      title="debtors.addLocation"
      primary-action-text="navigation.save"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="failed"
      @submit="submit"
    >
      <v-row>
        <v-col cols="12">
          <app-text-field v-model="newLocation.name" label="company" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <app-text-field v-model="newLocation.contactPerson" label="contactPerson" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <app-text-field v-model="newLocation.street" label="street" :required="!isAdmin" />
        </v-col>
        <v-col cols="4">
          <app-text-field v-model="newLocation.house" label="house" :required="!isAdmin" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <app-text-field
            v-model="newLocation.zip"
            label="zipCode"
            rules="required|numeric|length: 5"
          />
        </v-col>
        <v-col cols="8">
          <app-text-field v-model="newLocation.city" label="city" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <app-text-field v-model="newLocation.phone" label="phoneNumber" rules="phone" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <app-text-field v-model="newLocation.email" label="email" mode="lazy" rules="email" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <app-select
            v-model="newLocation.deliveryType"
            :items="deliveryTypes"
            label="debtors.deliveryType"
            :disabled="!newLocation.email"
            required
          />
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toLocation"
      secondary-action-text="navigation.backToDebtor"
      title="addresses.addressAdded"
      @submit="redirectToLocation"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { DELIVERY_TYPES, DeliveryType } from '@/statics/deliveryType';
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapActions } from 'vuex';

export default {
  name: 'AddLocationDialog',

  mixins: [ShowDialogMixin],

  data: () => ({
    newLocation: {
      name: '',
      contactPerson: '',
      email: '',
      deliveryType: DeliveryType.POST,
      phone: '',
      street: '',
      house: '',
      city: '',
      zip: ''
    },
    newLocationId: ''
  }),

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  computed: {
    deliveryTypes() {
      return DELIVERY_TYPES.map((type) => ({
        label: this.$t(`addresses.deliveryTypes.${type}`),
        value: type
      }));
    }
  },

  watch: {
    'newLocation.email'() {
      if (!this.newLocation.email && this.newLocation.deliveryType === DeliveryType.EMAIL) {
        this.newLocation.deliveryType = DeliveryType.POST;
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['addLocation']),

    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const payload = {
        debtorIdentifier: this.debtor.id,
        locationData: {
          name: this.newLocation.name,
          contactPerson: this.newLocation.contactPerson,
          address: {
            street: this.newLocation.street,
            house: this.newLocation.house,
            city: this.newLocation.city,
            zip: this.newLocation.zip
          },
          type: this.newLocation.deliveryType,
          email: this.newLocation.email,
          phone: this.newLocation.phone,
          isMainAddress: false
        }
      };
      const { locationId, error } = await this.addLocation(payload);

      this.loading = false;
      if (error) {
        return;
      }

      this.newLocationId = locationId;
      this.submitted = true;
    },

    redirectToLocation() {
      this.$router.push({
        params: { type: 'sub', id: `${this.debtor.id},${this.newLocationId}` },
        query: { ...this.$route.query }
      });
      this.hideAfterSuccess();
    }
  }
};
</script>
