<template>
  <v-container fluid pa-5 pt-10>
    <v-row class="my-5" no-gutters>
      <v-col>
        <h3 class="font-weight-bold">{{ details.name }}</h3>
        <div v-if="hasAdminView" class="caption text--muted">
          {{ details.type | formatDebtorType }}
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-4" justify="space-between" align="stretch">
      <v-col>
        <h3>
          <app-status-chip :value="details.status" />
        </h3>
        <div class="caption text--muted">{{ $t('status') }}</div>
      </v-col>
    </v-row>

    <v-row justify="space-between" style="white-space: nowrap">
      <v-col>
        <h3>{{ details.leftoverAmount | formatCurrency }}</h3>
        <div class="caption text--muted">{{ $t('debtors.debt') }}</div>
      </v-col>
      <v-col v-if="permissions.debtorDetails.tabLimit" class="text-right">
        <h3>{{ details.limit.availableLimit | formatCurrency }}</h3>
        <div class="caption text--muted">{{ $t('debtors.availableLimit') }}</div>
      </v-col>
      <v-col v-if="permissions.debtorDetails.showSolvency && details.solvency" class="text-right">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-end">
            <app-tooltip v-if="formattedReasonCode">
              <template #text>
                <div class="caption pa-2">
                  {{ formattedReasonCode }}
                </div>
              </template>
              <icon-info class="mr-2" />
            </app-tooltip>
          </v-col>
          <v-col>
            <h3>
              {{ $t('debtors.solvency') }}:
              <img :src="solvencyImageSrc" :alt="$t('debtors.solvency')" />
            </h3>
          </v-col>
        </v-row>
        <div class="caption text--muted">
          {{ $t('lastUpdate') }}: {{ details.solvency.updated | formatDate }}
        </div>
      </v-col>
    </v-row>

    <app-alert v-if="details.limit.isOnBlacklist" class="my-4" text="debtors.isOnBlacklist" />

    <app-action-buttons :actions="actions" />

    <app-tabs
      :key="details.id"
      v-model="activeTab"
      class="mt-8"
      :tabs="filterItemsWithFulfilledCondition(tabs)"
    >
      <component
        :is="filterItemsWithFulfilledCondition(tabs)[activeTab].component"
        class="mt-4"
        :details="details"
      />
    </app-tabs>

    <div v-if="hasAdminView">
      <v-divider style="margin-top: 5rem" />
      <v-row>
        <v-col>
          <h3>{{ details.sapNumber || '-' }}</h3>
          <span class="caption text--muted">{{ $t('sapNumber') }}</span>
        </v-col>
      </v-row>
      <process-log :data="processLog" />
      <app-api-log
        v-if="processLogRaw"
        :data="formattedProcessLog"
        :api-name="`Process-Log: ${$tc('resultsCounted', processLogRaw.length)}`"
      />
      <app-api-log :data="detailedDebtorRaw" api-name="debtor/show/{identifier}" />
    </div>
  </v-container>
</template>

<script>
import TabDebtorInfo from '@/modules/Debtors/components/Details/TabDebtorInfo';
import TabDebtorLocations from '@/modules/Debtors/components/Details/TabDebtorAddress';
import TabDebtorInvoices from '@/modules/Debtors/components/Details/TabDebtorInvoices';
import TabDebtorLimit from '@/modules/Debtors/components/Details/TabDebtorLimit';
import FormattedProcessLogMixin from '@/mixins/FormattedProcessLogMixin';
import ProcessLog from '@/shared/components/ProcessLog';
import { mapState } from 'vuex';
import { NAMESPACE } from '@/modules/Debtors/store';
import { GREEN, YELLOW, RED } from '@/modules/Debtors/statics/solvencyRanges';
import { formatSolvencyReasonCode } from '@/helper/formatSolvencyReasonCode';

export default {
  name: 'DebtorDetails',

  mixins: [FormattedProcessLogMixin],

  components: {
    ProcessLog
  },

  data: () => ({
    activeTab: 0
  }),

  props: {
    debtorActions: {
      type: Array,
      default: () => []
    },
    details: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['detailedDebtorRaw', 'processLog', 'processLogRaw']),
    actions() {
      return [
        this.queryParam.EDIT_DEBTOR,
        this.queryParam.EDIT_PAYMENT_DATA,
        this.queryParam.CHECK_SOLVENCY,
        this.queryParam.REQUEST_LIMIT_INCREASE,
        this.queryParam.REFRESH_LIMIT,
        this.queryParam.ACTIVATE,
        this.queryParam.DEACTIVATE
      ].map((query) => this.debtorActions.find((action) => action.param === query));
    },
    tabs() {
      return [
        {
          id: 1,
          name: this.$t('info'),
          component: TabDebtorInfo
        },
        {
          id: 2,
          name: this.$t('addressesPl'),
          component: TabDebtorLocations,
          condition: !this.details.isInactive
        },
        {
          id: 3,
          name: this.$t('invoices'),
          component: TabDebtorInvoices
        },
        {
          id: 4,
          name: this.$t('debtors.limit'),
          component: TabDebtorLimit,
          condition: this.permissions.debtorDetails.tabLimit
        }
      ];
    },
    solvencyImageSrc() {
      if (this.details.solvency.color === GREEN) {
        return require('@/modules/Debtors/assets/solvency-green.svg');
      }
      if (this.details.solvency.color === YELLOW) {
        return require('@/modules/Debtors/assets/solvency-yellow.svg');
      }
      if (this.details.solvency.color === RED) {
        return require('@/modules/Debtors/assets/solvency-red.svg');
      }
      return '';
    },
    formattedReasonCode() {
      if (!this.details.solvency) {
        return '';
      }
      return formatSolvencyReasonCode(this.details.solvency.reasonCode, this.details.type);
    }
  },

  watch: {
    'details.id'() {
      this.activeTab = 0;
    }
  }
};
</script>
