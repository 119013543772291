<template>
  <v-container class="px-0">
    <app-labeled-data
      text-right
      no-gutters
      label="debtors.customDebtorNumber"
      :value="details.customNumber"
    />

    <app-labeled-data
      text-right
      no-gutters
      :label="details.isBusiness ? $t('company') : $t('name')"
      :value="details.name"
    />

    <app-labeled-data
      v-if="details.isBusiness"
      text-right
      no-gutters
      label="contactPerson"
      :value="details.contactPerson"
    />

    <app-labeled-data
      text-right
      no-gutters
      label="street"
      :value="details.address.streetAndHouse"
    />

    <app-labeled-data
      text-right
      no-gutters
      :label="`${$t('zip')} / ${$t('city')}`"
      :value="`${details.address.zip} ${details.address.city}`"
    />

    <app-labeled-data text-right no-gutters label="phoneNumber" :value="details.phone" />

    <app-labeled-data text-right no-gutters label="email" :value="details.email" />

    <app-labeled-data
      text-right
      no-gutters
      label="debtors.invoiceDeliveryType"
      :value="$t(`addresses.deliveryTypes.${details.deliveryType}`)"
    />

    <v-row no-gutters justify="space-between" align="baseline">
      <v-col cols="12">
        <v-divider class="mb-4" />
      </v-col>

      <v-col cols="auto">
        <div class="caption text-uppercase grey--text semi-bold mr-4">
          {{ $t('debtors.separatePostAddress') }}
        </div>
      </v-col>
      <v-col cols="auto">
        <div class="text-right">
          <app-link-btn
            v-if="permissions.debtorDetails.editPostAddress"
            :title="details.postAddress ? $t('edit') : $t('add')"
            variant="muted"
            @click="setQueryParam(queryParam.ACTION, queryParam.EDIT_POST_ADDRESS)"
          />
          <app-link-btn
            v-if="details.postAddress && permissions.debtorDetails.deletePostAddress"
            class="ml-2"
            title="delete"
            variant="muted"
            @click="setQueryParam(queryParam.ACTION, queryParam.DELETE_POST_ADDRESS)"
          />
        </div>
      </v-col>
      <v-col cols="12" class="mt-2">
        <template v-if="details.postAddress">
          <div>{{ details.postAddress.name || details.debtor.name }}</div>
          <div v-if="details.postAddress.streetAndHouse">
            {{ details.postAddress.streetAndHouse }}
          </div>
          <div v-else-if="details.postAddress.postBox">
            {{ $t('debtors.postBox') }} {{ details.postAddress.postBox }}
          </div>
          <div>{{ details.postAddress.zip }} {{ details.postAddress.city }}</div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TabLocationInfo',

  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style scoped>
#app.v-application:not(.mobile) .row {
  margin-bottom: 35px !important;
}
</style>
